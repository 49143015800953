<script lang="ts" setup>
import { ref, onMounted, computed, watch } from "vue";
import autoAnimate from "@formkit/auto-animate";
import Combobox from "../combobox/Combobox.vue";
import { DeliveryLocation } from "../../utils/API/Delivery/deliveryAPISchema";
import { formatDeliveryLocationAddress } from "../../../../helpers/checkoutHelpers";
import { useDeliveryInformationStore } from "./stores/deliveryInformationStore";
import searchbox from "../searchbox/searchbox.vue";

const props = defineProps<{
  deliveryLocations?: DeliveryLocation[];
  selectedDeliveryLocationId?: string;
}>();

const deliveryInformationStore = useDeliveryInformationStore();
const containerElement = ref();
// Ref to control the visibility of the searchbox
const isSearchboxVisible = ref(false);

/**
 * Creates and array of objects that satisfy the ComboboxOption interface for usage in the Combobox component.
 *
 * @returns {Array} The options for the combobox.
 */
const comboboxOptions = computed(() => {
  return (
    props.deliveryLocations?.map((location: DeliveryLocation) => ({
      heading: location.name || "",
      description: formatDeliveryLocationAddress(location),
      id: location.id,
    })) || []
  );
});

const setDeliveryLocationById = (id: string) => {
  const location = props.deliveryLocations?.find(
    (location) => location.id === id,
  );

  if (location) {
    deliveryInformationStore.setSelectedDeliveryLocation(location);
  }
};

// Toggle searchbox visibility when the "Endre" button is clicked
const toggleSearchboxVisibility = () => {
  isSearchboxVisible.value = !isSearchboxVisible.value;
};

onMounted(() => {
  if (containerElement.value) autoAnimate(containerElement.value);
});
</script>

<template>
  <div
    v-if="
      deliveryInformationStore.selectedDeliveryMethod?.name === 'PickUpInStore'
    "
    ref="containerElement"
    class="checkout-delivery__locations"
  >
    <Combobox
      @update:modelValue="setDeliveryLocationById"
      v-if="props.deliveryLocations"
      :heading="$t('selectDeliveryLocation')"
      :hide-heading="true"
      :options="comboboxOptions"
      :selected-id="deliveryInformationStore.selectedDeliveryLocation?.id"
    />
  </div>
  <div
    v-if="deliveryInformationStore.selectedDeliveryMethod?.name === 'MyPack'"
  >
    <div v-if="deliveryInformationStore.selectedDeliveryLocation">
      <div class="searchbox__selected">
        <div class="searchbox__address-information">
          <div class="combo__heading">
            {{ deliveryInformationStore.selectedDeliveryLocation?.name }}
          </div>
          <div class="combo__description">
            {{
              formatDeliveryLocationAddress(
                deliveryInformationStore.selectedDeliveryLocation,
              )
            }}
          </div>
        </div>
        <div>
          <button
            class="checkout-step-selector__edit-step checkout-step-selector__activator"
            @click.prevent="toggleSearchboxVisibility"
          >
            <span class="checkout-step-selector__edit-text">{{
              $t("change")
            }}</span>
          </button>
        </div>
      </div>
    </div>
    <div v-if="isSearchboxVisible" class="searchbox">
      <label class="searchbox__label" for="postalCodeInput">{{
        $t("changeDeliveryLocation")
      }}</label>
      <searchbox :toggle-visibility="toggleSearchboxVisibility" />
    </div>
  </div>
</template>
