// useLazy.ts
import { onMounted, onBeforeUnmount, ref } from "vue";

export function useLazy(fetch: () => void) {
    const lazyObserver = ref<IntersectionObserver | null>(null);

    onMounted(() => {
        if (!("IntersectionObserver" in window)) {
            fetch();
        } else {
            lazyObserver.value = new IntersectionObserver(
                (entries) => {
                    if (entries[0].isIntersecting) {
                        fetch();
                    }
                },
                { threshold: [0] },
            );

            lazyObserver.value.observe(
                document.querySelector(".product-list")!,
            );
        }
    });

    onBeforeUnmount(() => {
        if (lazyObserver.value) {
            lazyObserver.value.disconnect();
        }
    });
}
