export interface ProductPrice {
    min: number;
    max: number;
}

export interface ProductBadge {
    theme: string;
    label: string;
}

export interface ProductVariant {
    inStock: boolean;
    key: string;
    size?: string;
    link: string;
    listPrice: number;
    sellingPrice: number;
    stockNumber: number;
    ticket: string;
}

export interface CustomField {
    id: string;
    label: string;
}

export interface ProductImageSource {
    width: number;
    height: number;
    url: string;
}

export interface ProductImage {
    alt: string;
    sources: Array<ProductImageSource>;
}

export interface Product {
    key: string;
    brand: string;
    link: string;
    title: string;
    ticket: string;
    inStock: boolean;
    isInStockOnWeb?: boolean;
    isNews?: boolean;
    badges: {
        primary?: ProductBadge;
        secondary?: ProductBadge;
    };
    sellingPrice: ProductPrice;
    listPrice: ProductPrice;
    imageInfo: {
        effects: string;
        images: Array<ProductImage>;
        thumbnail: string;
    };
    variants: Array<ProductVariant>;
    custom: {
        EpiPoductCode: Array<CustomField>;
        Dept: Array<CustomField>;
        Category: Array<CustomField>;
        ColorCode: Array<CustomField>;
        ColorName: Array<CustomField>;
        RGBColorCode: Array<CustomField>;
        MetaColor: Array<CustomField>;
        IsMatrixSize: Array<CustomField>;
        IsSuitSize: Array<CustomField>;
        GtmStockStatus: Array<CustomField>;
        HighlightText: Array<CustomField>;
        MemberPromotion: Array<CustomField>;
    };
}

export interface ProductSuggestion {
    key: string;
    products: Array<Product>;
}

export interface PhraseSuggestion {
    highlighted: string;
    q: string;
}

export interface ContentImageSource {
    width: number;
    height: number;
    url: string;
}

export interface ContentImage {
    alt: string;
    sources: Array<ContentImageSource>;
}

export interface ContentItem {
    key: string;
    link: string;
    title: string;
    ticket: string;
    type: string;
    image: ContentImage;
    custom: {
        Address: Array<{ id; label }>;
        Phone: Array<{ id; label }>;
        OpeningHours?: Array<{ id; label }>;
        MainIntro?: Array<{ id; label }>;
        Lat: Array<{ id; label }>;
        Lon: Array<{ id; label }>;
    };
}

export const CONTENT_LIST_ARTICLES = "Artikler";
export const CONTENT_LIST_STORES = "Butikker";

export interface ContentList {
    id: "Artikler" | "Butikker";
    items: Array<ContentItem>;
    totalHits: number;
}

export interface AutocompleteResult {
    contentLists: Array<ContentList>;
    productSuggestions: Array<ProductSuggestion>;
    phraseSuggestions: Array<PhraseSuggestion>;
    totalHits: number;
}

export enum FacetTypes {
    Color = "COLOR",
    Text = "TEXT",
    Range = "RANGE",
    Size = "SIZE",
}

export interface FacetValue {
    id: string;
    label: string;
    selected: boolean;
    color?: string;
    count: number;
}

export interface Format {
    format: string;
    values: Array<FacetValue>;
    id?: string;
}

export interface SizeType {
    label: string;
    formats: Array<Format>;
    id?: string;
}

export interface Facet {
    id: string;
    label: string;
    values?: Array<FacetValue>;
    type: FacetTypes | "COLOR" | "TEXT" | "RANGE" | "SIZE";
    sizeTypes?: Array<SizeType>;
    selectedCount?: number;
    min?: number;
    max?: number;
    minSelected?: number;
    maxSelected?: number;
}

export interface Pill {
    facetId: string;
    facetValueId: string;
    label: string;
}

export interface ProductGroup {
    key: string;
    products: Array<Product>;
}

export interface SortOption {
    id: string;
    label: string;
}

export interface Sort {
    options: Array<SortOption>;
    selected: string;
}

export interface SearchResult {
    q: string;
    autoCorrect: null | {
        q: string;
        originalTotalHits: number;
    };
    contentLists: Array<ContentList>;
    primaryList: null | PrimaryList
    message?: string | null;
}

export interface SearchResultRecommendList extends SearchResult {
    recommendationLists: Array<PrimaryList>;
    primaryList: null | PrimaryList;
}

export interface CategoryResult {
    primaryList: null | PrimaryList;
    message?: string;
}

export interface ProductsResult {
    primaryList: null | PrimaryList;
}

export interface PrimaryList {
    productGroups: Array<ProductGroup>;
    facets: Array<Facet>;
    totalHits: number;
    sort: Sort;
}

export interface CartStatusResult {
    primaryList: null | PrimaryList;
}
