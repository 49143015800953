<template>
  <!-- TODO: Styles and classes -->
  <div id="app-cart-status">
    <!-- Modal -->
    <section
      class="cart-status"
      :class="{ 'is-active': isOpen }"
      aria-live="polite"
      :aria-hidden="!isOpen"
      role="dialog"
    >
      <h2 class="sr-only">{{ srHeading }}</h2>
      <div class="cart-status__bg" role="button" @click="clickClose"></div>
      <article class="cart-status__content">
        <div class="cart-status__product">
          <header class="cart-status__text">
            <div>
              <button
                class="btn btn--small btn--link btn--close cart-status__close"
                type="button"
                :title="$t('close')"
                @click="clickClose"
              >
                <Icon :type="IconTypes.Close" :size="IconSizes.Small" />
              </button>
              <span class="cart-status__message">{{ message }}</span>
              <h3 class="cart-status__heading">{{ heading }}</h3>
              <p class="cart-status__desc">{{ description }}{{ size }}</p>
            </div>
            <div class="cart-status__buttons u-leader">
              <a
                ref="checkoutButton"
                :href="checkoutUrl"
                class="btn btn--profile u-trailer-half"
              >
                {{ $t("checkout") }}
              </a>
              <button
                role="button"
                class="btn u-trailer-half"
                @click="clickClose"
              >
                {{ $t("continue") }}
              </button>
            </div>
          </header>
          <div class="cart-status__image">
            <picture>
              <source type="image/webp" :srcset="imageWebP" />
              <img
                alt=""
                width="300"
                height="400"
                loading="lazy"
                :src="imageFallback"
              />
            </picture>
          </div>
        </div>
        <!-- Cart status result: Products -->
        <div
          v-if="
            cartStatusResult &&
            cartStatusResult.primaryList &&
            cartStatusResult.primaryList.productGroups &&
            cartStatusResult.primaryList.productGroups.length > 0
          "
          class="cart-status__recommendations"
        >
          <h3>{{ recommendationsHeading }}</h3>
          <div class="cart-status__recommendations-wrapper">
            <ul id="category-list" class="category-grid" data-layout="4">
              <template
                v-for="productGroup in cartStatusResult.primaryList
                  .productGroups"
              >
                <template
                  v-if="productGroup.products && productGroup.products[0]"
                >
                  <li
                    :key="`${productGroup.key}-${productGroup.products[0].key}`"
                    class="category-grid__item"
                  >
                    <ProductTeaser
                      :product-group="productGroup"
                      :product="productGroup.products[0]"
                      :is-user-authenticated="isUserAuthenticated"
                      :hideFavoriteForm="false"
                    />
                  </li>
                </template>
              </template>
            </ul>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SearchService, { CartStatusResult } from "../../services/search.service";
import Icon from "../../components/icon/icon.vue";
import { IconSizes, IconTypes } from "../../components/icon/icon.model";
import ProductTeaser from "../../components/productTeaser/productTeaser.vue";
import { useFavoritesStore } from "../../stores/favoritesStore";

export default defineComponent({
  components: {
    Icon,
    ProductTeaser,
  },
  props: [
    "userAuthenticated",
    "srHeading",
    "message",
    "heading",
    "description",
    "recommendationsHeading",
    "checkoutUrl",
    "imageWebP",
    "imageFallback",
  ],
  data() {
    return {
      IconSizes,
      IconTypes,
      isOpen: false,
      size: "",
      isLoading: false,
      cartStatusResult: null as CartStatusResult | null,
    };
  },
  computed: {
    isUserAuthenticated(): boolean {
      return this.userAuthenticated === "True";
    },
  },
  watch: {
    isOpen(val, prevVal) {
      if (val === true && prevVal === false) {
        this.afterDisplay();
      }
      if (val === false && prevVal === true) {
        this.afterClose();
      }
    },
  },
  mounted() {
    this.$el.addEventListener("displayCartStatus", this.display);
  },
  destroyed() {
    this.$el.removeEventListener("displayCartStatus", this.display);
  },
  methods: {
    /**
     * Display
     */
    display(event: Event) {
      this.size = (event as CustomEvent).detail.sizeText;
      this.isOpen = true;
    },
    /**
     * After display
     */
    afterDisplay() {
      const favoritesStore = useFavoritesStore();

      // Focus checkout button
      if (this.$refs.checkoutButton) {
        (this.$refs.checkoutButton as HTMLButtonElement).focus();
      }
      // Get favorites
      if (
        this.isUserAuthenticated &&
        !favoritesStore.favorites &&
        !favoritesStore.isLoadingFavorites
      ) {
        favoritesStore.getFavorites();
      }
      // Get recommendations
      if (!this.cartStatusResult) {
        this.getRecommendations();
      }
    },
    /**
     * Click close
     */
    clickClose() {
      this.isOpen = false;
    },
    /**
     * After close
     */
    afterClose() {
      this.size = "";
    },
    /**
     * Get recommendations
     */
    getRecommendations() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      const searchPromise = SearchService.cartStatus(
        "todo", // Product ID?
      );

      searchPromise
        .then((result) => {
          this.cartStatusResult = result.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.cartStatusResult = null;

          console.error(error);
        });
    },
  },
});
</script>
