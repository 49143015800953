import { defineStore } from "pinia";
import { Cart, KeySellingPoint, LineItemModel } from "../../../utils/API/Cart/cartAPISchema";

export const useShoppingCartStore = defineStore("shoppingCart", {
    state: () => ({
        cart: null as Cart | null | undefined,
        ksps: null as KeySellingPoint[] | null | undefined,
        discounts: [] as string[] | null | undefined,
        lineItemsOutOfStock: [] as LineItemModel[],
        lineItemErrors: {} as Record<string, string> | null | undefined,
        summaryError: "" as string | null | undefined,
        discountError: "" as string | null | undefined,
    }),
    actions: {
        setCart(cart: Cart | null | undefined) {
            this.cart = cart;
        },
        setKsps(ksps: KeySellingPoint[] | null | undefined) {
            this.ksps = ksps;
        },
        addDiscountCode(discount: string) {
            if (!this.discounts) return;
            this.discounts.push(discount);
            this.discounts = [...this.discounts];
        },
        removeDiscountCode(discount: string) {
            if (!this.discounts) return;
            this.discounts = this.discounts.filter((d) => d !== discount);
        },
        setLineItemsOutOfStock(lineItems: LineItemModel[]) {
            this.lineItemsOutOfStock = lineItems;
        },
        setLineItemError(lineItemEan: string, error: string) {
            this.lineItemErrors = {
                ...this.lineItemErrors,
                [lineItemEan]: error,
            };
        },
        setLineItemErrors(errors: Record<string, string> | null | undefined) {
            this.lineItemErrors = errors;
        },
        setSummaryError(error: string | null | undefined) {
            this.summaryError = error;
        },
        setDiscountError(error: string | null | undefined) {
            this.discountError = error;
        },
    },
    getters: {
        esalesKeys: (state) => {
            return state.cart?.lineItems.map((li) => li.esalesKey) ?? [];
        }
    }
});
