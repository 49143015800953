import { LineItemModel } from "../utils/API/Cart/cartAPISchema";
import { GoogleAnalytics4EventModel, GoogleAnalytics4EventModelSchema, Item } from "../utils/API/GoogleAnalytics/GoogleAnalyticsSchema";
import { handleGtmTracking } from "../utils/API/GTM";
import { LogErrorResponse } from "../utils/API/Logging/LoggingAPI";

export const pushDataLayerEvent = async (eventName: string, lineItem?: LineItemModel) => {
    const object = await getEventModel(eventName, lineItem?.code);
    handleGtmTracking(object);
}

const getEventModel = async (eventName: string, lineItemCode?: string): Promise<GoogleAnalytics4EventModel> => {
    const url = `/api/tracking/checkoutevent${lineItemCode ? `?itemCode=${lineItemCode}` : ""}`;
    
    const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        await LogErrorResponse(url, response);
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const validatedData = GoogleAnalytics4EventModelSchema.parse(data);
    validatedData.event = eventName;

    return validatedData;
};