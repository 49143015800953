import { Product } from "../services/search.service";
import { getCustomApptusValue } from "../utils/search.utils";

export const computePromotion = (
    product: Product,
    shortenPromotion?: boolean,
) => {
    const promotionLabel = getCustomApptusValue(product, "Promotion");

    if (shortenPromotion && promotionLabel) {
        return {
            className: "label--shortened",
            text: "Salg",
        };
    }

    if (promotionLabel) {
        return {
            className: "label--amountdiscount",
            text: promotionLabel,
        };
    }

    return undefined;
};

export const computeMemberPromotion = (
    promotionText?: string,
    shortenPromotion?: boolean,
) => {
    if (!promotionText) return undefined;

    if (shortenPromotion) {
        return {
            className: "label--member label--shortened",
            text: "Medlemstilbud",
        };
    }

    if (promotionText) {
        return {
            className: "label--member ",
            text: promotionText,
        };
    }

    return undefined;
};

export const computeBadge = (product: Product) => {
    const primaryBadges = product.badges?.primary;
    const secondaryBadges = product.badges?.secondary;

    const primaryBadge = primaryBadges;
    const secondaryBadge = secondaryBadges;

    const badge = primaryBadge || secondaryBadge;

    if (badge) {
        return {
            className: "label--amountdiscount", // TODO
            text: badge.label,
        };
    }
};
