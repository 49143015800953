<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useLazy } from "../../mixins/lazy";
import SearchService, { ProductsResult } from "../../services/search.service";
import ProductsModuleContent from "./productsModuleContent.vue";
import { useFavoritesStore } from "../../stores/favoritesStore";

const props = defineProps<{
  heading: string;
  baseUrlPath: string;
  apiUrl: string;
  userAuthenticated: string;
  favoriteFormHidden: string;
  lazy: string;
  limit: number;
  showAsColumnOnMobile: string;
  shortenPromotion?: string;
  useSkeletonLoaders?: string;
  hideSizes?: string;
  hideColors?: string;
  hidePromotion?: string;
  hideFavorites?: string;
  containerClass?: string;
}>();

const hasFetched = ref(false);
const productsResult = ref<ProductsResult | null>(null);
const isLoading = ref(false);
const favoritesStore = useFavoritesStore();

// Computed properties
const hideFavorites = computed(() => props.favoriteFormHidden === "True");
const isUserAuthenticated = computed(() => props.userAuthenticated === "True");
const isLazy = computed(() => props.lazy === "True");
const isShowAsColumnOnMobile = computed(
  () => props.showAsColumnOnMobile === "True",
);
const isLimit = computed(() => Number(props.limit));
const shouldHideSizes = computed(() => props.hideSizes === "True");
const shouldHideColors = computed(() => props.hideColors === "True");
const shouldHidePromotion = computed(() => props.hidePromotion === "True");
const shouldHideFavorites = computed(() => props.hideFavorites === "True");
const shouldShortenPromotion = computed(() => props.shortenPromotion === "True");
const shouldUseSkeletonLoaders = computed(() => props.useSkeletonLoaders === "True");

const fetch = () => {
  if (hasFetched.value) return;

  isLoading.value = true;

  SearchService.products(props.apiUrl, props.baseUrlPath, props.limit)
    .then((response) => {
      hasFetched.value = true;
      productsResult.value = response.data;
    })
    .catch((error) => {
      console.error(error);
    });

  isLoading.value = false;
};

// Use lazy loading if enabled
if (isLazy.value) {
  useLazy(fetch);
}

const handleScrollIntoView = () => {
  if (isLazy.value && !hasFetched.value) {
    useLazy(fetch);
  }
};

onMounted(() => {
  if (!isLazy.value) {
    fetch();
  }

  if (isUserAuthenticated.value) {
    favoritesStore.getFavorites();
  }
});
</script>
<template>
  <div class="product-list" @scrollIntoView="handleScrollIntoView">
    <ProductsModuleContent
      v-if="productsResult"
      :heading="props.heading"
      :products-result="productsResult"
      :show-as-column-on-mobile="isShowAsColumnOnMobile"
      :user-authenticated="isUserAuthenticated"
      :favorite-form-hidden="hideFavorites"
      :is-loading="isLoading"
      :product-count="isLimit"
      :shorten-promotion="shouldShortenPromotion"
      :use-skeleton-loaders="shouldUseSkeletonLoaders"
      :hide-promotion="shouldHidePromotion"
      :hide-sizes="shouldHideSizes"
      :hide-colors="shouldHideColors"
      :hide-favorites="shouldHideFavorites"
      :containerClass="props.containerClass"
    />
  </div>
</template>
