import { z } from "zod";

export type Item = z.infer<typeof ItemSchema>;
export const ItemSchema = z.object({
        item_id: z.string(),
        item_name: z.string(),
        affiliation: z.string().nullable().optional(), // Unused
        coupon: z.string().nullable().optional(),
        discount: z.number().nullable().optional(),
        index: z.number().nullable().optional(),
        item_brand: z.string(),
        item_category: z.string(), // Item categories in order, from least to most specific. E.g, "item_category: 'Apprarel', item_category2: 'Shoes', item_category3: 'Sneakers'
        item_category2: z.string().optional().nullable(),
        item_category3: z.string().optional().nullable(),
        item_category4: z.string().optional().nullable(),
        item_category5: z.string().optional().nullable(),
        item_list_id: z.string().nullable().optional(), // Position if the item exist in a list, e.g. a product list or a search result list
        item_list_name: z.string().nullable().optional(),
        item_variant: z.string(),
        location_id: z.string().nullable().optional(), // Unused
        price: z.number(),
        quantity: z.number().min(1),
        item_size: z.string().optional().nullable(), // Custom property
        item_discount_percentage: z.number().nullable().optional(), // Custom property
    });

export type EcommerceEventModel = z.infer<typeof EcommerceEventModelSchema>;
export const EcommerceEventModelSchema = z.object({
  currency: z.string(),
  value: z.number(),
  coupon: z.string().optional().nullable(),
  items: z.array(ItemSchema),
  transaction_id: z.string().optional().nullable(),
  tax: z.number().optional().nullable(), // Unused
  shipping: z.number().optional().nullable(),
  shipping_tier: z.string().optional().nullable(),
  payment_type: z.string().optional().nullable(),
});

export type GoogleAnalytics4EventModel = z.infer<typeof GoogleAnalytics4EventModelSchema>;
export const GoogleAnalytics4EventModelSchema = z.object({
    event: z.string(),
    logged_in_status: z.boolean(),
    ecommerce: EcommerceEventModelSchema,
});