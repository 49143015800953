import { defineStore } from "pinia";
import {
    CheckoutStage,
    CheckoutStages,
    CheckoutStep,
    CheckoutSteps,
} from "../../../services/models/checkout.models";

export const useCheckoutStateStore = defineStore("checkoutState", {
    state: () => ({
        currentCheckoutStep: CheckoutSteps.CustomerInformation as CheckoutStep,
        currentCheckoutStage: CheckoutStages.ShoppingCart as CheckoutStage,
        isLoading: false,
        baseUrlPath: "",
    }),
    actions: {
        setIsLoading(isLoading: boolean) {
            this.isLoading = isLoading; 
        },
        setCheckoutStage(stage: CheckoutStage) {
            this.currentCheckoutStage = stage;
        },
        setToCheckoutStep(step: CheckoutStep) {
            this.currentCheckoutStep = step;
        },
        setBaseUrlPath(baseUrlPath: string) {
            this.baseUrlPath = baseUrlPath;
        }
    },
});
