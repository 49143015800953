import { getCustomerInformation } from "../utils/API/Customer/customerAPI";
import { useCustomerInformationStore } from "../components/checkout/stores/customerInformationStore";
import {
    CheckoutStage,
    CheckoutStages,
    CheckoutStep,
    CheckoutSteps,
} from "./models/checkout.models";
import { useCheckoutStateStore } from "../components/checkout/stores/checkoutStateStore";
import {
    AddressModel,
    AddressModelSchema,
    AddressModelWithoutAnalytics,
    AddressModelWithoutAnalyticsSchema,
    Cart,
} from "../utils/API/Cart/cartAPISchema";
import { getCityFromPostalCode } from "../utils/API/Postal/postalAPI";
import { USER_FEEDBACK } from "../utils/API/APIErrors";
import { FEEDBACK_TEXT } from "../utils/validationHelpers";
import { useDeliveryInformationStore } from "../components/checkout/stores/deliveryInformationStore";
import {
    createAddDeliveryMethodRequestDtoSchema,
    SetDeliveryOptionResponseModel,
    UpdateDeliveryRequestDto,
} from "../utils/API/Delivery/deliveryAPISchema";
import {
    getDeliveryInformation,
    setDeliveryOption,
} from "../utils/API/Delivery/deliveryAPI";
import { getCart, updateCustomer } from "../utils/API/Cart/cartAPI";
import { getPaymentBody, toGoogleAnalyticsEventName } from "../../../helpers/checkoutHelpers";
import {
    setPaymentMethod,
    getPaymentMethods,
} from "../utils/API/Payment/paymentAPI";
import {
    createAddPaymentMethodRequestDtoSchema,
    SelectPaymentResultDtoSchema,
} from "../utils/API/Payment/paymentAPISchema";
import { useShoppingCartStore } from "../components/shoppingCart/stores/shoppingCartStore";
import { usePaymentInformationStore } from "../components/checkout/stores/paymentInformationStore";
import { nextTick } from "vue";
import { pushDataLayerEvent } from "./gtm.service";

/**
 * Attempts to retrieve customer information from the API.
 * If no customer information is found, verification is needed (User is not logged in).
 *
 * @returns {boolean} Returns true if verification is needed, false otherwise.
 */
export const verificationIsNeeded = async () => {
    const customerInformationStore = useCustomerInformationStore();

    const customerData = await apiGetCustomerInformation();
    if (!customerData) return true;

    customerInformationStore.setFields(customerData);
    return false;
};

/**
 * Fetches customer information from the API and updates the customerInformation value.
 * Sets the cartIsLoading value to true before making the API call and sets it back to false after the call is completed.
 *
 * @returns {Promise<void>} A promise that resolves when the customer information is fetched and updated successfully.
 */
export const apiGetCustomerInformation = async () => {
    try {
        const data = await getCustomerInformation();

        // Convert null fields to empty strings
        for (let key in data) {
            if (data[key] === null) {
                data[key] = "";
            }
        }

        return data;
    } catch (error) {
        console.error(error);
    }
};

/**
 * Checks if a step in the checkout process has been completed.
 *
 * @param step - The step to check.
 * @returns A promise that resolves to a boolean indicating whether the step has been completed.
 */
const stepCompleted = async (step: CheckoutStep): Promise<boolean> => {
    const checkoutStateStore = useCheckoutStateStore();

    if (checkoutStateStore.isLoading) return false;
    checkoutStateStore.setIsLoading(true);

    if (step === CheckoutSteps.CustomerInformation) {
        const result = await submitCustomerInformationForm();
        checkoutStateStore.setIsLoading(false);
        return result;
    }

    if (step === CheckoutSteps.Delivery) {
        const result = await submitDeliveryInformationForm();
        checkoutStateStore.setIsLoading(false);
        return result;
    }

    if (step === CheckoutSteps.Payment) {
        const result = await submitPaymentInformationForm();
        checkoutStateStore.setIsLoading(false);
        return result;
    }

    checkoutStateStore.setIsLoading(false);
    return false;
};

/**
 * Triggers the submission of the customer information form.
 * If the form is valid, the customer information is updated using the API.
 * If the form is invalid, the form errors are set and the form is not submitted.
 *
 * @returns {Promise<void>} A promise that resolves when the form is submitted successfully.
 */
const submitCustomerInformationForm = async () => {
    const customerInformationStore = useCustomerInformationStore();

    customerInformationStore.setErrorSummaryVisible(true);

    const validationResult = AddressModelWithoutAnalyticsSchema.safeParse(
        customerInformationStore.fields,
    );

    if (!validationResult.success) {
        customerInformationStore.clearAllErrors();

        validationResult.error.errors.forEach((error) => {
            const path = error.path[0];
            customerInformationStore.setError(path, [
                ...customerInformationStore.errors[path],
                ...[error.message],
            ]);
        });
    }

    let matchingCityResult;
    if (validationResult.success) {
        matchingCityResult = await getCityFromPostalCode(
            validationResult.data.postalCode,
        );

        if (matchingCityResult === USER_FEEDBACK.UNKNOWN_POSTALCODE) {
            customerInformationStore.setError("postalCode", [
                FEEDBACK_TEXT().UNKNOWN_POSTALCODE,
            ]);
            return false;
        }

        if (matchingCityResult !== validationResult.data.city.toUpperCase()) {
            customerInformationStore.setError("postalCode", [
                FEEDBACK_TEXT().POSTALCODE_AND_CITY_DONT_MATCH,
            ]);
            return false;
        }
    }

    if (
        !validationResult.success ||
        matchingCityResult == USER_FEEDBACK.UNKNOWN_POSTALCODE
    ) {
        return false;
    }

    const success = await apiUpdateCustomer(validationResult.data);
    if (!success) return false;

    setSubmittedCustomerInformation(validationResult.data);
    customerInformationStore.clearAllErrors();
    return true;
};

/**
 * Submits the delivery information form.
 * If the form is valid, the delivery information is updated using the API.
 * If the form is invalid, the form errors are set and the form is not submitted.
 *
 * @returns {boolean} Returns `true` if the form submission is successful, otherwise `false`.
 */
export const submitDeliveryInformationForm = async () => {
    const deliveryInformationStore = useDeliveryInformationStore();

    if (
        !deliveryInformationStore.selectedDeliveryMethod ||
        !deliveryInformationStore.selectedDeliveryMethod?.id
    ) {
        deliveryInformationStore.setError(
            "deliveryMethod",
            FEEDBACK_TEXT().REQUIRED_DELIVERY_METHOD,
        );
        return false;
    }

    const requestBody: UpdateDeliveryRequestDto = {
        deliveryLocationId:
            deliveryInformationStore.selectedDeliveryLocation?.id ?? undefined,
    };

    if (deliveryInformationStore.selectedDeliveryWindow) {
        requestBody.deliveryWindow =
            deliveryInformationStore.selectedDeliveryWindow ?? undefined;
    }

    const validationSchema = createAddDeliveryMethodRequestDtoSchema(
        deliveryInformationStore.selectedDeliveryMethod,
    );

    const validationResult = validationSchema.safeParse(requestBody);

    if (!validationResult.success) {
        setDeliveryInformationErrors(validationResult.error);
        return false;
    }

    const data = await apiUpdateDelivery(
        deliveryInformationStore.selectedDeliveryMethod.id,
        requestBody,
    );

    if (!data) return false;

    const shoppingCartStore = useShoppingCartStore();
    deliveryInformationStore.clearAllErrors();
    shoppingCartStore.setCart(data.cartDto);
    return true;
};

/**
 * Submits the payment information form.
 * If the form is valid, the payment information is updated using the API.
 * If the form is invalid, the form errors are set and the form is not submitted.
 *
 * @returns {boolean} Returns true if the payment information form is successfully submitted, otherwise false.
 */
const submitPaymentInformationForm = async () => {
    const customerInformationStore = useCustomerInformationStore();
    const paymentInformationStore = usePaymentInformationStore();
    customerInformationStore.clearAllErrors();
    paymentInformationStore.clearAllErrors();

    if (
        !paymentInformationStore.selectedPaymentMethod ||
        !paymentInformationStore.selectedPaymentMethod.systemKeyword
    ) {
        paymentInformationStore.setError("paymentMethod", [
            FEEDBACK_TEXT().REQUIRED_PAYMENT_METHOD,
        ]);
        return false;
    }

    const success = await apiSetPaymentMethod();
    if (!success) {
        return false;
    }

    return true;
};

/**
 * Sets the payment method for the checkout process.
 * If the selected payment method and payment system keyword are available, the payment method is set using the API.
 *
 * @returns {Promise<boolean>} A promise that resolves to true if the payment method is set successfully, otherwise false.
 */
const apiSetPaymentMethod = async (): Promise<boolean> => {
    const paymentInformationStore = usePaymentInformationStore();

    if (
        !paymentInformationStore.selectedPaymentMethod ||
        !paymentInformationStore.selectedPaymentMethod.systemKeyword
    ) {
        return false;
    }

    const requestBody = getPaymentBody(
        paymentInformationStore.selectedPaymentMethod,
        paymentInformationStore.personIdentifier,
        paymentInformationStore.selectedAfterPayInstallmentOption,
        paymentInformationStore.selectedAfterPayInstallmentPlanId,
    );

    const validationSchema = createAddPaymentMethodRequestDtoSchema(
        paymentInformationStore.selectedPaymentMethod,
    );

    const bodyValidationResult = validationSchema.safeParse(requestBody);

    if (!bodyValidationResult.success) {
        paymentInformationStore.clearAllErrors();

        bodyValidationResult.error.errors.forEach((error) => {
            const path = error.path[0];
            paymentInformationStore.setError(path, [
                ...paymentInformationStore.errors[path],
                ...[error.message],
            ]);
        });
        return false;
    }

    let response;
    try {
        response = await setPaymentMethod(
            paymentInformationStore.selectedPaymentMethod.systemKeyword,
            requestBody,
        );

        if (!response) return false;
    } catch (error) {
        paymentInformationStore.setError("finalizeOrder", [
            FEEDBACK_TEXT().GENERIC_ERROR,
        ]);
        return false;
    }

    const responseValidationResult =
        SelectPaymentResultDtoSchema.safeParse(response);

    if (!responseValidationResult.success) {
        return false;
    }

    if (responseValidationResult.data.errorMessage) {
        paymentInformationStore.setError("finalizeOrder", [
            responseValidationResult.data.errorMessage as string,
        ]);
        return false;
    }

    if (responseValidationResult.data.cartResultDto) {
        paymentInformationStore.setCartContentChangedPaymentContent(
            responseValidationResult.data.cartResultDto,
        );
        paymentInformationStore.SetShowCartContentChangedPaymentError(true);
        return false;
    }

    paymentInformationStore.setThirdpartyPaymentUrl(
        responseValidationResult?.data?.url,
    );
    const customerInformationStore = useCustomerInformationStore();
    customerInformationStore.clearAllErrors();
    paymentInformationStore.clearAllErrors();
    return true;
};

/**
 * Updates the customer information using the API.
 *
 * @async
 * @function apiUpdateCustomer
 * @returns {Promise} A promise that resolves when the customer information is successfully updated.
 */
const apiUpdateCustomer = async (
    customerInformation: AddressModelWithoutAnalytics,
): Promise<boolean> => {
    try {
        const success = await updateCustomer(customerInformation);
        if (success) return true;
        return false;
    } catch (error) {
        if (error instanceof Error) {
            console.log(error.message);
        }
    }
    return false;
};

/**
 * Updates the delivery method for the checkout.
 *
 * @param {string} deliveryMethodId - The ID of the delivery method.
 * @param {UpdateDeliveuseCustomerInformationStore.ClearAllErrorso} request - The request object containing the updated delivery information.
 * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating whether the update was successful.
 */
const apiUpdateDelivery = async (
    deliveryMethodId: string,
    request: UpdateDeliveryRequestDto,
): Promise<SetDeliveryOptionResponseModel | null> => {
    try {
        const data = await setDeliveryOption(deliveryMethodId, request);
        return data;
    } catch (error) {
        if (error instanceof Error) {
            console.log(error.message);
        }
    }
    return null;
};

/**
 * Sets the delivery information errors.
 *
 * @param {Object} errors - The errors object containing the path and message.
 */
const setDeliveryInformationErrors = (errors) => {
    const deliveryInformationStore = useDeliveryInformationStore();
    deliveryInformationStore.setErrors(errors);
};

/**
 * Sets the payment information errors.
 *
 * @param {Object} errors - The errors object containing the path and message.
 */
const setPaymentInformationErrors = (errors) => {
    const paymentInformationStore = usePaymentInformationStore();
    paymentInformationStore.setErrors(errors);
};

/**
 * Checks if all checkout steps prior to the given toStep have been completed.
 *
 * @param {CheckoutStep} toStep - The step to check against.
 * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating whether all prior steps have been completed.
 */
const priorStepsCompleted = async (toStep: CheckoutStep) => {
    for (const step of Object.values(CheckoutSteps)) {
        if (step === toStep) {
            return true;
        }

        const completed = await stepCompleted(step);
        if (!completed) {
            return false;
        }
    }

    return true;
};

/**
 * Navigates to the specified checkout step if the current step is completed.
 * This is necessary to ensure that required data for the next step is available before use.
 *
 * @param {CheckoutStep} toStep - The checkout step to navigate to.
 */
export const goToCheckoutStep = async (toStep: CheckoutStep) => {
    const okToProceed = await priorStepsCompleted(toStep);
    const checkoutStateStore = useCheckoutStateStore();

    if (checkoutStateStore.currentCheckoutStep === toStep || !okToProceed)
        return;

    if (
        toStep == CheckoutSteps.Delivery &&
        checkoutStateStore.currentCheckoutStep ===
            CheckoutSteps.CustomerInformation
    ) {
        await apiGetDeliveryInformation();
    }

    const paymentInformationStore = usePaymentInformationStore();

    if (
        toStep == CheckoutSteps.Payment &&
        !paymentInformationStore.paymentMethods
    ) {
        await apiGetPaymentMethods();
    }

    if (toStep === CheckoutSteps.FinalizeOrder) {
        tryToFinalizeOrder();
    }

    pushDataLayerEvent(toGoogleAnalyticsEventName(toStep));
    nextTick(() => (checkoutStateStore.currentCheckoutStep = toStep));
};

/**
 * Tries to finalize the order by redirecting to the third-party payment URL.
 */
const tryToFinalizeOrder = () => {
    const paymentInformationStore = usePaymentInformationStore();
    if (paymentInformationStore.thirdpartyPaymentUrl) {
        window.location.href = paymentInformationStore.thirdpartyPaymentUrl;
    }
};

/**
 * Fetches payment options from the API and updates the divs value.
 *
 * @returns {Promise<void>} A promise that resolves when the payment options are fetched and updated successfully.
 */
const apiGetPaymentMethods = async () => {
    const paymentInformationStore = usePaymentInformationStore();
    const checkoutStateStore = useCheckoutStateStore();

    try {
        const data = await getPaymentMethods();
        paymentInformationStore.paymentMethods = data.paymentMethodsDto.paymentMethods;
        paymentInformationStore.selectedPaymentMethod =
            data?.paymentMethodsDto.paymentMethods.find((method) => method.isDefault) ?? data[0];
    } catch (error) {
        console.error(error);
    }
};

/**
 * Fetches the delivery information from the API and updates the deliveryInformation and selectedDeliveryMethodId variables.
 *
 * @returns {Promise<void>} A promise that resolves when the delivery information is fetched and updated successfully.
 */
const apiGetDeliveryInformation = async () => {
    const deliveryInformationStore = useDeliveryInformationStore();
    const checkoutStateStore = useCheckoutStateStore();

    try {
        const data = await getDeliveryInformation();
        deliveryInformationStore.deliveryInformation = data.deliveryDto;

        deliveryInformationStore.setSelectedDeliveryMethod(
            data.deliveryDto.deliveryMethods?.find((option) => option.isDefaultSelection),
        );
    } catch (error) {
        console.error(error);
    }
};

/**
 * Fetches the shopping cart data from the API and updates the shopping cart store.
 *
 * This function asynchronously calls the `getShoppingCart` API to retrieve the current
 * shopping cart data. Upon successful retrieval, it updates the shopping cart store
 * with the fetched data. If an error occurs during the API call, it logs the error to the console.
 *
 * @async
 * @function apiGetShoppingCart
 * @returns {Promise<void>} A promise that resolves when the shopping cart data has been fetched and the store updated.
 */
export const apiGetShoppingCart = async () => {
    const shoppingCartStore = useShoppingCartStore();

    try {
        const data = await getCart();
        shoppingCartStore.setCart(data.cartDto);
    } catch (error) {
        console.error(error);
    }
};

/**
 * Sets the submitted customer information.
 *
 * @param {AddressModel} customerInformation - The customer information to be set.
 */
const setSubmittedCustomerInformation = (
    customerInformation: AddressModelWithoutAnalytics,
) => {
    const customerInformationStore = useCustomerInformationStore();
    customerInformationStore.setSubmittedFields(customerInformation);
};

export const goToCheckoutStage = async (stage: CheckoutStage) => {
    const checkoutStateStore = useCheckoutStateStore();

    if (stage === CheckoutStages.Verification) {
        checkoutStateStore.setIsLoading(true);

        const verificationNeeded = await verificationIsNeeded();
        stage = verificationNeeded ? CheckoutStages.Verification : CheckoutStages.Checkout;

        checkoutStateStore.setIsLoading(false);
    }

    if (stage === CheckoutStages.Checkout) {
        pushDataLayerEvent(toGoogleAnalyticsEventName(CheckoutSteps.CustomerInformation));
    }

    checkoutStateStore.setCheckoutStage(stage);
};

export const goToCheckoutStageAfterLogin = async () => {
    const checkoutStateStore = useCheckoutStateStore();
    const customerInformationStore = useCustomerInformationStore();

    const customerInformation = await apiGetCustomerInformation();
    customerInformationStore.setFields(customerInformation);

    checkoutStateStore.currentCheckoutStage = CheckoutStages.Checkout;
    pushDataLayerEvent(toGoogleAnalyticsEventName(CheckoutSteps.CustomerInformation));
};

/**
 * Retrieves the city matching the given postal code.
 *
 * @param {string} postalCode - The postal code to match.
 * @returns {Promise<string>} - A promise that resolves to the matching city.
 */
export const TryGetCityMatchingPostalCode = async (postalCode: string) => {
    if (!postalCode) return;
    const customerInformationStore = useCustomerInformationStore();
    customerInformationStore.setRetrievingCity(true);

    const matchingCityResult = await getCityFromPostalCode(postalCode);

    customerInformationStore.setRetrievingCity(false);
    return matchingCityResult;
};
