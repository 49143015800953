<script lang="ts" setup>
import { computed, defineProps, watch, ref, onMounted } from "vue";
import { SkuDetails } from "./sizePicker.models";
import { useAddToCartStore } from "./stores/addToCartStore";
import Icon from "../../components/icon/icon.vue";
import { IconSizes, IconTypes } from "../../components/icon/icon.model";
import { formatSize } from "../../mixins/productSize.mixin";

const props = defineProps<{
  chooseSize: string;
  sizeGuide: string;
  isSizeGuide: boolean;
  selectSizeMessage: string;
  skuParameterCode: string;
  inventoryNotificationTrigger: string;
  sizesListJson?: string;
}>();

const addToCartStore = useAddToCartStore();

// Computed properties for store states
const displaySizeNotSelectedError = computed(
  () => addToCartStore.displaySizeNotSelectedError,
);

// Function to handle size change
function handleSizeChange(size) {
  addToCartStore.setSizeSelected(true);
  addToCartStore.setSizeText(formatSize(size));
  const skuCode = getSkuCode(size);
  addToCartStore.setSkuCode(skuCode);
}

// Parse and store sizesList from JSON
const sizesList = computed<SkuDetails[][]>(() => {
  if (!props.sizesListJson) return [];
  try {
    return JSON.parse(props.sizesListJson);
  } catch (error) {
    console.error("Failed to parse sizesListJson:", error);
    return [];
  }
});

const isOneSize = computed(() => {
  return sizesList.value?.length === 1 && sizesList.value[0].length === 1;
});

const regularSizes = computed(() => {
  if (!sizesList.value) return [];
  let sizes = new Set<string>();

  sizesList.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      sizes.add(skuDetails.regularSize);
    });
  });

  const sizesArray = Array.from(sizes);

  // Check if all sizes are numeric
  const areSizesNumeric = sizesArray.every((size) => !isNaN(Number(size)));

  if (areSizesNumeric) {
    // Sort sizes numerically
    return sizesArray.sort((a, b) => Number(a) - Number(b));
  }

  // Return sizes as is if they are not numeric
  return sizesArray;
});

// Get in-stock regular sizes (different from available sizes)
const inStockRegularSizes = computed(() => {
  if (!sizesList.value) return [];
  let inStockRegularSizesSet = new Set<string>();

  sizesList.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      if (skuDetails.inventoryCount > 0) {
        inStockRegularSizesSet.add(skuDetails.regularSize);
      }
    });
  });

  return Array.from(inStockRegularSizesSet);
});

const soldOutSizes = computed<SkuDetails[]>(() => {
  return sizesList.value
    .flat()
    .filter((skuDetails) => skuDetails.inventoryCount === 0);
});

function isWideSize(size: string): boolean {
  return size.length >= 4;
}

function getSkuCode(regularSize: string): string {
  if (!sizesList.value) return "";
  let skuCode: string | undefined = undefined;

  sizesList.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      if (skuDetails.regularSize === regularSize) {
        skuCode = skuDetails.skuCode;
      }
    });
  });

  return skuCode || "";
}

function getInventoryCount(regularSize: string): string {
  if (!sizesList.value) return "0";
  let inventoryCount = "0";

  sizesList.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      if (skuDetails.regularSize === regularSize) {
        inventoryCount = skuDetails.inventoryCount.toString();
      }
    });
  });

  return inventoryCount;
}

// Reactive state for selected values
const selectedRegularSize = ref<string>("");

// Function to automatically select a single in-stock option if only one exists
const autoSelectSingleOption = () => {
  if (inStockRegularSizes.value.length === 1) {
    selectedRegularSize.value = inStockRegularSizes.value[0];
    addToCartStore.setSizeSelected(true);
    addToCartStore.setSkuCode(getSkuCode(inStockRegularSizes.value[0]));
    addToCartStore.setSizeText(formatSize(inStockRegularSizes.value[0]));
    addToCartStore.setInventoryCount(
      getInventoryCount(inStockRegularSizes.value[0]),
    );
    addToCartStore.setCheckInventoryStatus(true);
  }
};

// Call the function when the component is mounted
onMounted(() => {
  autoSelectSingleOption();
});

// Watch for changes in `selectedRegularSize` and perform operations
watch(selectedRegularSize, (newSize) => {
  if (!newSize) return;
  // Update the SKU code and inventory count when size is selected
  addToCartStore.setSkuCode(getSkuCode(newSize));
  addToCartStore.setSizeText(formatSize(newSize));
  addToCartStore.setInventoryCount(getInventoryCount(newSize));

  // Trigger inventory status check
  addToCartStore.setCheckInventoryStatus(true);
});

</script>
<template>
  <div class="sizepicker">
    <div class="size-tile-group">
      <!-- Size Information -->
      <div class="product__size-information">
        <span id="selectedsize" class="size-tile-group__group--title">
          <span v-if="!selectedRegularSize">{{ chooseSize }}:</span>
          <!-- Render the selected size when selected -->
          <span v-if="selectedRegularSize">
            {{ chooseSize }}:
            <strong>{{ formatSize(selectedRegularSize) }}</strong>
          </span>
        </span>
        <!-- Size Guide Button (Conditional) -->
        <span v-if="isSizeGuide" class="product__size-guide">
          <button
            type="button"
            class="product__size-guide btn btn--small btn--link js-btn _js-vue-component-toggler"
            data-togglewhat="sizeguide"
          >
            {{ sizeGuide }}
          </button>
        </span>
      </div>

      <fieldset aria-labelledby="selectsizelabel" class="product__size__normal">
        <!-- Sizes -->
        <div
          class="size-tile-group__items"
          :class="isOneSize ? 'size-tile-group__items--onesize' : ''"
        >
          <div
            v-for="size in regularSizes"
            :key="size"
            :class="[
              'size-tile-group__item',
              isWideSize(formatSize(size))
                ? 'size-tile-group__item--wide'
                : 'size-tile-group__item--normal',
            ]"
          >
            <input
              :id="'size_' + size"
              v-model="selectedRegularSize"
              :name="skuParameterCode"
              type="radio"
              :data-sku="
                selectedRegularSize ? getSkuCode(selectedRegularSize) : ''
              "
              :value="size"
              class="size-tile-group__input _js-check-stock"
              :disabled="!inStockRegularSizes.includes(size)"
              @change="handleSizeChange(size)"
            />
            <label
              :for="'size_' + size"
              :class="[
                'size-tile-group__label',
                {
                  'size-tile-group__label--soldout _js-inventory-soldout':
                    !inStockRegularSizes.includes(size),
                },
              ]"
            >
              <span class="size-tile-group__text">{{ formatSize(size) }}</span>
            </label>
          </div>
        </div>
      </fieldset>
      <!-- Error Message if size not selected -->
      <span v-if="displaySizeNotSelectedError" class="size-tile-group__error">
        {{ selectSizeMessage }}
      </span>
    </div>
    <!-- Inventory Low Notification -->
    <span v-if="addToCartStore.inventoryIsLow" class="size-tile-group__warning">
      {{ addToCartStore.inventoryLowMessage }}
    </span>
    <div
      v-if="soldOutSizes.length > 0"
      class="product__inventory-notification _js-vue-inventory-nofitication-btn-container"
    >
      <button
        class="product__inventory-notification btn--link _js-vue-component-toggler _js-vue-inventory-notification-btn"
        data-togglewhat="inventoryNotification"
      >
        <Icon :type="IconTypes.Bell" :size="IconSizes.Tiny" />
        <p>
          {{ inventoryNotificationTrigger }}
        </p>
      </button>
    </div>
  </div>
</template>
