<script lang="ts" setup>
import { computed, defineProps, watch, ref, onMounted } from "vue";
import { SkuDetails } from "./sizePicker.models";
import { useAddToCartStore } from "./stores/addToCartStore";
import Icon from "../../components/icon/icon.vue";
import { IconSizes, IconTypes } from "../../components/icon/icon.model";
import { formatSize } from "../../mixins/productSize.mixin";

const props = defineProps<{
  sizesMatrixJson: string;
  chooseWidth: string;
  chooseLength: string;
  sizeGuide: string;
  isSizeGuide: boolean;
  selectWidthMessage: string;
  selectLengthMessage: string;
  skuParameterCode: string;
  inventoryNotificationTrigger: string;
}>();

const addToCartStore = useAddToCartStore();

const displayWidthNotSelectedError = computed(
  () => addToCartStore.displayWidthNotSelectedError,
);

const displayLengthNotSelectedError = computed(
  () => addToCartStore.displayLengthNotSelectedError,
);

// Parse and store sizesMatrix from JSON
const sizesMatrix = computed<SkuDetails[][]>(() => {
  if (!props.sizesMatrixJson) return [];
  try {
    return JSON.parse(props.sizesMatrixJson);
  } catch (error) {
    console.error("Failed to parse sizesMatrixJson:", error);
    return [];
  }
});

const pantsLengths = computed(() => {
  if (!sizesMatrix.value) return [];
  let lengths = new Set<string>();

  sizesMatrix.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      lengths.add(skuDetails.matrixSizeL);
    });
  });

  return Array.from(lengths).sort((a, b) => Number(a) - Number(b));
});

const pantsWidths = computed(() => {
  if (!sizesMatrix.value) return [];
  let widths = new Set<string>();

  sizesMatrix.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      widths.add(skuDetails.matrixSizeW);
    });
  });

  const widthsArray = Array.from(widths);

  // Check if all widths are numeric
  const areWidthsNumeric = widthsArray.every((width) => !isNaN(Number(width)));

  if (areWidthsNumeric) {
    // Sort numerically
    return widthsArray.sort((a, b) => Number(a) - Number(b));
  } else {
    // Sort by size labels
    const sizeOrder = [
      "XXS",
      "XS",
      "S",
      "M",
      "L",
      "XL",
      "XXL",
      "XXXL",
      "XXXXL",
    ];
    return widthsArray.sort(
      (a, b) => sizeOrder.indexOf(a) - sizeOrder.indexOf(b),
    );
  }
});

// Get in-stock pants lengths (different from available lengths)
const inStockPantsLengths = computed(() => {
  if (!sizesMatrix.value) return [];
  let inStockpantsLengthsSet = new Set<string>();

  sizesMatrix.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      if (skuDetails.inventoryCount > 0) {
        inStockpantsLengthsSet.add(skuDetails.matrixSizeL);
      }
    });
  });

  // Convert the Set to an array and sort numerically
  return Array.from(inStockpantsLengthsSet).sort(
    (a, b) => Number(a) - Number(b),
  );
});

// Get in-stock pant widths (different from available widths)
const inStockPantsWidths = computed(() => {
  if (!sizesMatrix.value) return [];
  let inStockpantsWidthsSet = new Set<string>();

  sizesMatrix.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      if (skuDetails.inventoryCount > 0) {
        inStockpantsWidthsSet.add(skuDetails.matrixSizeW);
      }
    });
  });

  // Convert the Set to an array and sort numerically
  return Array.from(inStockpantsWidthsSet).sort(
    (a, b) => Number(a) - Number(b),
  );
});

const soldOutSizes = computed<SkuDetails[]>(() => {
  return sizesMatrix.value
    .flat()
    .filter((skuDetails) => skuDetails.inventoryCount === 0);
});

// Function to get SkuCode for a given width, length, and size
function getSkuCode(width: string, length: string) {
  let skuCode: string | undefined = undefined;

  sizesMatrix.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      if (
        skuDetails.matrixSizeW === width &&
        skuDetails.matrixSizeL === length
      ) {
        skuCode = skuDetails.skuCode;
      }
    });
  });

  return skuCode || "";
}

// Function to get inventory count for a given width and length
function getInventoryCount(width: string, length: string) {
  let inventoryCount = "0";

  sizesMatrix.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      if (
        skuDetails.matrixSizeW === width &&
        skuDetails.matrixSizeL === length
      ) {
        inventoryCount = skuDetails.inventoryCount.toString();
      }
    });
  });

  return inventoryCount;
}

// Function to get available lengths for a given width and size
function getAvailableLengths(pantsWidth: string) {
  const availableLengths = new Set<string>();

  sizesMatrix.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      if (
        skuDetails.matrixSizeW === pantsWidth &&
        skuDetails.inventoryCount > 0 // Check inventory count
      ) {
        availableLengths.add(skuDetails.matrixSizeL);
      }
    });
  });

  return Array.from(availableLengths);
}

// Function to get available widths for a given length and size
function getAvailableWidths(pantsLength: string) {
  const availableWidths = new Set<string>();

  sizesMatrix.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      if (
        skuDetails.matrixSizeL === pantsLength &&
        skuDetails.inventoryCount > 0 // Check inventory count
      ) {
        availableWidths.add(skuDetails.matrixSizeW);
      }
    });
  });

  return Array.from(availableWidths);
}

// Reactive state for selected values
const selectedPantsWidth = ref<string>("");
const selectedPantsLength = ref<string>("");

// Reactive available options based on selected values
const availableWidths = ref<string[]>(pantsWidths.value);
const availableLengths = ref<string[]>(pantsLengths.value);

// Function to automatically select a single in-stock option if only one exists
const autoSelectSingleOption = () => {
  if (inStockPantsWidths.value.length === 1) {
    selectedPantsWidth.value = inStockPantsWidths.value[0];
    addToCartStore.setWidthSelected(true);
  }
  if (inStockPantsLengths.value.length === 1) {
    selectedPantsLength.value = inStockPantsLengths.value[0];
    addToCartStore.setLengthSelected(true);
  }

  if (selectedPantsWidth.value && selectedPantsLength.value) {
    addToCartStore.setSkuCode(
      getSkuCode(selectedPantsWidth.value, selectedPantsLength.value),
    );
    addToCartStore.setSizeText(
      formatSize(selectedPantsWidth.value + selectedPantsLength.value),
    );
    addToCartStore.setInventoryCount(
      getInventoryCount(selectedPantsWidth.value, selectedPantsLength.value),
    );
    addToCartStore.setCheckInventoryStatus(true);
  }
};

// Call the function when the component is mounted
onMounted(() => {
  autoSelectSingleOption();
});

// Watch for changes in both `selectedPantsWidth` and `selectedPantsLength`
watch([selectedPantsWidth, selectedPantsLength], ([newWidth, newLength]) => {
  if (newWidth && newLength) {
    // Update the SKU code and inventory count when both sizes are selected
    addToCartStore.setSkuCode(getSkuCode(newWidth, newLength));
    addToCartStore.setSizeText(formatSize(newWidth + newLength));
    addToCartStore.setInventoryCount(getInventoryCount(newWidth, newLength));

    // Trigger inventory status check
    addToCartStore.setCheckInventoryStatus(true);
  }
});

// Watchers to update available options based on selections
watch(
  [selectedPantsWidth, selectedPantsLength],
  () => {
    let tempAvailableWidths = pantsWidths.value;
    let tempAvailableLengths = pantsLengths.value;

    if (selectedPantsWidth.value) {
      // Update available lengths based on the selected width
      tempAvailableLengths = getAvailableLengths(selectedPantsWidth.value);
    }

    if (selectedPantsLength.value) {
      // Update available widths based on the selected length
      tempAvailableWidths = getAvailableWidths(selectedPantsLength.value);
    }

    // Set the reactive variables with the updated options
    availableWidths.value = tempAvailableWidths;
    availableLengths.value = tempAvailableLengths;
  },
  { immediate: true },
);

// Watchers to update the skuCode when width or length are selected/changes
watch(
  [selectedPantsWidth, selectedPantsLength],
  ([newWidth, newLength]) => {
    if (newWidth && newLength) {
      addToCartStore.setSkuCode(getSkuCode(newWidth, newLength));
      addToCartStore.setSizeText(formatSize(newWidth + newLength));
    }
  },
  { immediate: true },
);

</script>

<template>
  <div class="sizepicker sizepicker--pants">
    <!-- Widths Section -->
    <div class="size-tile-group">
      <!-- Width Information -->
      <div class="product__size-information">
        <span id="selectedsize" class="size-tile-group__group--title">
          <span v-if="!selectedPantsWidth">{{ chooseWidth }}:</span>
          <!-- Conditionally render the selected width when selected -->
          <span v-if="selectedPantsWidth">
            {{ chooseWidth }}:
            <strong>{{ selectedPantsWidth }}</strong>
          </span>
        </span>
        <!-- Size Guide Button (Conditional) -->
        <span v-if="isSizeGuide" class="product__size-guide">
          <button
            type="button"
            class="product__size-guide btn btn--small btn--link js-btn _js-vue-component-toggler"
            data-togglewhat="sizeguide"
          >
            {{ sizeGuide }}
          </button>
        </span>
      </div>

      <!-- Widths -->
      <!-- If width, size, and length are all selected, 
        the input value gets populated with the skuCode -->
      <div class="size-tile-group__items size-tile-group__items--width">
        <div
          v-for="width in pantsWidths"
          :key="width"
          class="size-tile-group__item"
        >
          <input
            :id="'width_' + width"
            v-model="selectedPantsWidth"
            :name="skuParameterCode"
            :value="width"
            type="radio"
            :data-sku="
              selectedPantsWidth && selectedPantsLength
                ? getSkuCode(selectedPantsWidth, selectedPantsLength)
                : ''
            "
            class="size-tile-group__input _js-check-stock"
            :disabled="
              !availableWidths.includes(width) ||
              !inStockPantsWidths.includes(width)
            "
            @change="addToCartStore.setWidthSelected(true)"
          />
          <label
            :for="'width_' + width"
            :class="[
              'size-tile-group__label',
              {
                'size-tile-group__label--soldout _js-inventory-soldout':
                  !availableWidths.includes(width) ||
                  !inStockPantsWidths.includes(width),
              },
            ]"
          >
            <span class="size-tile-group__text">{{ width }}</span>
          </label>
        </div>
      </div>
      <!-- Error Message if width not selected -->
      <span v-if="displayWidthNotSelectedError" class="size-tile-group__error">
        {{ selectWidthMessage }}
      </span>
    </div>

    <!-- Lengths Section -->
    <div class="size-tile-group">
      <!-- Length Information -->
      <div class="product__size-information">
        <span id="selectedsize" class="size-tile-group__group--title">
          <span v-if="!selectedPantsLength">{{ chooseLength }}:</span>
          <!-- Conditionally render the selected length when selected -->
          <span v-if="selectedPantsLength">
            {{ chooseLength }}:
            <strong>{{ selectedPantsLength }}</strong>
          </span>
        </span>
      </div>

      <!-- Lengths -->
      <div class="size-tile-group__items size-tile-group__items--length">
        <div
          v-for="length in pantsLengths"
          :key="length"
          class="size-tile-group__item size-tile-group__item--normal"
        >
          <input
            :id="'length_' + length"
            v-model="selectedPantsLength"
            type="radio"
            :value="length"
            class="size-tile-group__input"
            :disabled="
              !availableLengths.includes(length) ||
              !inStockPantsLengths.includes(length)
            "
            @change="addToCartStore.setLengthSelected(true)"
          />
          <label
            :for="'length_' + length"
            :class="[
              'size-tile-group__label',
              {
                'size-tile-group__label--soldout _js-inventory-soldout':
                  !availableLengths.includes(length) ||
                  !inStockPantsLengths.includes(length),
              },
            ]"
          >
            <span class="size-tile-group__text">{{ length }}</span>
          </label>
        </div>
      </div>
      <!-- Error Message if length not selected -->
      <span v-if="displayLengthNotSelectedError" class="size-tile-group__error">
        {{ selectLengthMessage }}
      </span>
    </div>
    <!-- Inventory Low Notification -->
    <span v-if="addToCartStore.inventoryIsLow" class="size-tile-group__warning">
      {{ addToCartStore.inventoryLowMessage }}
    </span>
    <div
      v-if="soldOutSizes.length > 0"
      class="product__inventory-notification _js-vue-inventory-nofitication-btn-container"
    >
      <button
        class="product__inventory-notification btn--link _js-vue-component-toggler _js-vue-inventory-notification-btn"
        data-togglewhat="inventoryNotification"
      >
        <Icon :type="IconTypes.Bell" :size="IconSizes.Tiny" />
        <p>
          {{ inventoryNotificationTrigger }}
        </p>
      </button>
    </div>
  </div>
</template>
