<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import { ComboboxOptionModel } from "../../services/models/combobox.models";
import { useDeliveryInformationStore } from "../checkout/stores/deliveryInformationStore";
import { getDeliveryLocations } from "../../utils/API/Delivery/deliveryAPI";
import { useCustomerInformationStore } from "../checkout/stores/customerInformationStore";
import { DeliveryLocation } from "../../utils/API/Delivery/deliveryAPISchema";
import { IconSizes, IconTypes } from "../icon/icon.model";
import Icon from "../icon/icon.vue";

const customerInformationStore = useCustomerInformationStore();
const deliveryInformationStore = useDeliveryInformationStore();
const previousSearchQuery = ref("");
const query = ref(customerInformationStore.fields.postalCode || "");
const selectedDeliveryLocation = ref();
const comboboxOptions = ref<ComboboxOptionModel[]>([]);
const searchInput = ref<HTMLInputElement | null>(null);

const handleSearchInput = (event: InputEvent) => {
  const input = event.target as HTMLInputElement;
  const value = input.value;

  // Allow only digits and limit to 4 characters
  if (/^\d*$/.test(value) && value.length <= 4) {
    query.value = value;
  } else {
    // Revert to the previous valid value if the input is invalid
    input.value = query.value;
  }
};

const resetInput = () => {
  query.value = "";
  // Small timeout to ensure DOM update is rendered before focusing
  setTimeout(() => {
    const inputElement = document.getElementById(
      "postalCodeInput",
    ) as HTMLInputElement | null;
    inputElement?.focus();
  }, 0);
};

const props = defineProps<{
  toggleVisibility: () => void;
}>();

const handleSelectComboOption = (option: ComboboxOptionModel) => {
  // Parse the description to extract delivery address, postal code, and city
  const [deliveryAddress, postalCodeCity] =
    option.description?.split(",") ?? [];
  const [postalCode, city] = postalCodeCity?.trim().split(" ") ?? [];

  // Create a new DeliveryLocation object from the selected option
  const selectedLocation: DeliveryLocation = {
    id: option.id.toString(), // Ensure the id is a string
    name: option.heading, // Use the heading as the name
    deliveryAddress: deliveryAddress?.trim(),
    postalCode: postalCode?.trim(),
    city: city?.trim(),
  };

  // Set the selected delivery location
  selectedDeliveryLocation.value = selectedLocation;

  // Update the deliveryInformationStore with the selected location
  deliveryInformationStore.setSelectedDeliveryLocation(selectedLocation);

  // Toggle visibility after selecting an option
  props.toggleVisibility();
};

const apiGetDeliveryLocations = async () => {
  const data = await getDeliveryLocations(
    deliveryInformationStore?.selectedDeliveryMethod?.name ?? "MyPack",
    query.value,
  );

  comboBoxMapping(data);
};

const comboBoxMapping = (data: Array<DeliveryLocation>) => {
  // Map the API data to the ComboboxOptionModel format
  comboboxOptions.value = data.map((location: any) => ({
    id: location.id,
    heading: location.name,
    description: `${location.deliveryAddress}, ${location.postalCode} ${location.city}`,
  }));
};

//When query reaches 4 digits and the query is not the same as the previous query, log to console
watch(query, (value) => {
  if (value.length === 4 && value !== previousSearchQuery.value) {
    previousSearchQuery.value = value;
    apiGetDeliveryLocations();
  }
});

onMounted(() => {
  if (query.value.length === 4) {
    apiGetDeliveryLocations();
  }
});
</script>

<template>
  <div class="searchbox__container">
    <input
      class="searchbox__input"
      id="postalCodeInput"
      :value="query"
      @input.prevent="handleSearchInput"
      placeholder="Skriv inn et postnummer..."
      ref="searchInput"
    />
    <button
      v-if="query"
      class="searchbox__reset-button"
      @click.prevent="resetInput"
      aria-label="Reset input"
    >
      <Icon :type="IconTypes.Close" :size="IconSizes.Small" />
    </button>
  </div>
  <ul v-if="comboboxOptions.length > 0" class="searchbox__results">
    <li v-for="option in comboboxOptions" :key="option.id">
      <button
        @click.prevent="handleSelectComboOption(option)"
        class="searchbox__result"
      >
        <div class="searchbox__result-content">
          <p class="combo__heading">{{ option.heading }}</p>
          <p class="combo__description">{{ option.description }}</p>
        </div>
      </button>
    </li>
  </ul>
</template>
