export type CheckoutTrackingEvent =
    (typeof CheckoutTrackingEvents)[keyof typeof CheckoutTrackingEvents];
export const CheckoutTrackingEvents = {
    BeginCheckout: "begin_checkout",
    AddShippingInfoAddress: "add_shipping_info_address",
    AddShippingInfo: "add_shipping_info",
    AddPaymentInfo: "add_payment_info",
    Purchase: "purchase",
    AddToCart: "add_to_cart",
    RemoveFromCart: "remove_from_cart",
} as const;