import { useShoppingCartStore } from "../components/shoppingCart/stores/shoppingCartStore";
import { API_ERROR_MESSAGES, API_ERROR_NAMES, USER_FEEDBACK } from "../utils/API/APIErrors";
import {
    addDiscountCode,
    removeDiscountCode,
    removeProductFromCart,
    updateProductQuantity,
} from "../utils/API/Cart/cartAPI";
import { LineItemModel } from "../utils/API/Cart/cartAPISchema";
import { pushDataLayerEvent } from "./gtm.service";
import { CheckoutTrackingEvents } from "./models/gtm.models";

/**
 * Deletes a product from the shopping cart.
 * Sets the `cart` value with the returned data.
 *
 * @param {number} code - The code of the product.
 * @param {number} ean - The european article number of the product.
 * @returns {Promise} - A promise that resolves when the product is successfully deleted.
 */
export const apiRemoveProductFromCart = async (
    code: string,
    ean: string,
): Promise<void> => {
    const shoppingCartStore = useShoppingCartStore();

    const lineItem = shoppingCartStore.cart?.lineItems.find(
        (x) => x.ean === ean,
    ) as LineItemModel;

    try {
        const data = await removeProductFromCart(code);
        shoppingCartStore.setCart(data.cartDto);
        pushDataLayerEvent(CheckoutTrackingEvents.RemoveFromCart, lineItem);
    } catch (error) {
        handleRemoveProductFromCartError(lineItem);
    }
};


/**
 * Updates the quantity of a product in the shopping cart.
 *
 * @param code - The product code.
 * @param ean - The EAN (European Article Number) of the product.
 * @param quantity - The quantity to of the product.
 * @param isAdditive - A flag indicating whether the quantity should be added to the existing quantity (true) or set as the new quantity (false).
 * @param signal - An AbortSignal to allow aborting the request.
 * @returns A promise that resolves when the update is complete.
 *
 * @throws {Error} If there is an error during the update process.
 */
export const apiUpdateProductQuantity = async (
    code: string,
    ean: string,
    quantity: number,
    isAdditive: boolean,
    signal: AbortSignal,
): Promise<void> => {
    const shoppingCartStore = useShoppingCartStore();

    const lineItem = shoppingCartStore.cart?.lineItems.find(
        (x) => x.ean === ean,
    ) as LineItemModel;

    try {
        const data = await updateProductQuantity(
            code,
            quantity,
            isAdditive,
            signal,
        );

        if (shoppingCartStore.lineItemErrors?.[lineItem.ean]) {
            const { [lineItem.ean]: _, ...rest } =
                shoppingCartStore.lineItemErrors;
            shoppingCartStore.setLineItemErrors(rest);
        }

        shoppingCartStore.setCart(data.cartDto);
        pushDataLayerEvent(quantity > 0 ? CheckoutTrackingEvents.AddToCart : CheckoutTrackingEvents.RemoveFromCart, lineItem);
    } catch (error) {
        if (error instanceof Error) {
            handleUpdateProductQuantityError(lineItem, error);
        }
    }
};

/**
 * Handles the error when deleting a product from the shopping cart.
 *
 * @param {LineItemModel} lineItem - The line item representing the product to be deleted.
 */
const handleRemoveProductFromCartError = (lineItem: LineItemModel) => {
    const shoppingCartStore = useShoppingCartStore();

    if (!lineItem || !shoppingCartStore.cart) return;

    shoppingCartStore.setLineItemErrors({
        ...shoppingCartStore.lineItemErrors,
        [lineItem.ean]: USER_FEEDBACK.GENERIC,
    });
};

/**
 * Handles the error that occurs when updating the quantity of a product in the shopping cart.
 * Adds line-item specific errors to the `lineItemErrors` object and sets the `summaryError` value otherwise.
 *
 * @param {Error} error - The error object that occurred during the update.
 * @param {LineItemModel} lineItem - The line item where the error occurred.
 */
const handleUpdateProductQuantityError = (
    lineItem: LineItemModel,
    error?: Error,
): void => {
    const shoppingCartStore = useShoppingCartStore();

    if (!error) {
        setLineItemError(lineItem, "");
        return;
    }

    if (error.name === API_ERROR_NAMES.ABORT_ERROR) {
        return;
    }

    if (!lineItem || !shoppingCartStore.cart) return;

    if (error.message === API_ERROR_MESSAGES.CART_FULL) {
        shoppingCartStore.setSummaryError(USER_FEEDBACK.CART_FULL);
        return;
    }

    if (error.message === USER_FEEDBACK.INSUFFICIENT_STOCK) {
        setLineItemError(lineItem, USER_FEEDBACK.INSUFFICIENT_STOCK);
        return;
    }

    setLineItemError(lineItem, USER_FEEDBACK.GENERIC);
};

/**
 * Sets the error value for a line item.
 *
 * @param {LineItemModel} lineItem - The line item to set the error for.
 * @param {string} errorValue - The error value to set.
 */
const setLineItemError = (lineItem: LineItemModel, errorValue: string) => {
    const shoppingCartStore = useShoppingCartStore();

    if (shoppingCartStore.lineItemErrors?.[lineItem.ean] === errorValue) return;

    shoppingCartStore.setLineItemErrors({
        ...shoppingCartStore.lineItemErrors,
        [lineItem.ean]: errorValue,
    });
};

/**
 * Adds a discount code to the shopping cart.
 * Sets the `cart` value with the returned data.
 *
 * @param {string} discountCode - The discount code to be added.
 */
export const apiAddDiscountCode = async (
    discountCode: string,
): Promise<void> => {
    const shoppingCartStore = useShoppingCartStore();

    try {
        const data = await addDiscountCode(discountCode);
        shoppingCartStore.setCart(data);
    } catch (error) {
        handleAddDiscountCodeError(error as string);
        return;
    }

    shoppingCartStore.addDiscountCode(discountCode);
    handleAddDiscountCodeError();
};

/**
 * Handles the error when adding a discount code.
 *
 * @param {string} error - The error message, if any.
 */
const handleAddDiscountCodeError = (error?: string) => {
    const shoppingCartStore = useShoppingCartStore();

    if (!error) shoppingCartStore.setDiscountError("");

    if (error === USER_FEEDBACK.COULD_NOT_ADD_DISCOUNT_CODE) {
        shoppingCartStore.setDiscountError(error);
    }

    if (error === USER_FEEDBACK.GENERIC) {
        shoppingCartStore.setDiscountError(error);
    }
};

/**
 * Removes a discount code from the shopping cart.
 * Sets the `cart` value with the returned data.
 *
 * @param {string} discountCode - The discount code to be removed.
 * @returns {Promise<void>} - A promise that resolves when the discount code is successfully removed.
 */
export const apiRemoveDiscountCode = async (
    discountCode: string,
): Promise<void> => {
    const shoppingCartStore = useShoppingCartStore();

    try {
        const data = await removeDiscountCode(discountCode);
        shoppingCartStore.setCart(data);
    } catch (error) {
        handleRemoveDiscountCodeError(error as string);
    }

    shoppingCartStore.removeDiscountCode(discountCode);
    handleRemoveDiscountCodeError();
};

/**
 * Handles the error when removing a discount code.
 *
 * @param {string} error - The error message, if any.
 */
const handleRemoveDiscountCodeError = (error?: string) => {
    const shoppingCartStore = useShoppingCartStore();

    if (!error) shoppingCartStore.setDiscountError("");

    if (error === USER_FEEDBACK.GENERIC) {
        shoppingCartStore.setDiscountError(error);
    }
};

/**
 * Removes the line item with the specified EAN from the LineItemsOutOfStock list.
 *
 * @param {string} ean - The EAN (European Article Number) of the product.
 */
export const removeFromProductsOutOfStock = (_: string, ean: string) => {
    const shoppingCartStore = useShoppingCartStore();

    shoppingCartStore.lineItemsOutOfStock = shoppingCartStore.lineItemsOutOfStock.filter(
        (x) => x.ean !== ean,
    );
};

