import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";
import { set } from "zod";

export const useAddToCartStore = defineStore("cart", {
    state: () => ({
        totalProductCount: useLocalStorage<null | number>(
            "totalProductCount",
            0,
        ),
        sizeSelected: false,
        widthSelected: false,
        lengthSelected: false,
        sizeText: "",
        skuCode: "",
        displaySizeNotSelectedError: false,
        displayWidthNotSelectedError: false,
        displayLengthNotSelectedError: false,
        checkInventoryStatus: false,
        inventoryCount: "",
        inventoryIsLow: false,
        inventoryLowMessage: "Få igjen!",
    }),
    actions: {
        setCartItemCount(count: number) {
            this.totalProductCount = count;
        },
        incrementCartItemCount() {
            if (this.totalProductCount === null) {
                this.totalProductCount = 1;
            } else {
                this.totalProductCount = Number(this.totalProductCount) + 1;
            }
        },
        setSkuCode(value: string) {
            this.skuCode = value;
        },
        setSizeSelected(selected: boolean) {
            this.sizeSelected = selected;
            if (selected) {
                this.displaySizeNotSelectedError = false;
            }
        },
        setWidthSelected(selected: boolean) {
            this.widthSelected = selected;
            if (selected) {
                this.displayWidthNotSelectedError = false;
            }
        },
        setLengthSelected(selected: boolean) {
            this.lengthSelected = selected;
            if (selected) {
                this.displayLengthNotSelectedError = false;
            }
        },
        setCheckInventoryStatus(status: boolean) {
            this.checkInventoryStatus = status;
        },
        setInventoryCount(count: string) {
            this.inventoryCount = count;
        },
        setInventoryIsLow(status: boolean) {
            this.inventoryIsLow = status;
        },
        triggerSizeError() {
            this.displaySizeNotSelectedError = true;
        },
        setSizeText(text: string) {
            this.sizeText = text;
        },
    },
    getters: {
        cartEmpty(state) {
            return state.totalProductCount === 0;
        },
    },
});
