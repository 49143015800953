<template>
  <form @submit.prevent="submitForm">
    <div class="product__size">
      <SizePickerSuit
        v-if="props.sizePickerType === SizePickerTypes.SizePickerSuit"
        v-bind="sizePickerSuitProps"
      />
      <SizePickerPants
        v-if="props.sizePickerType === SizePickerTypes.SizePickerPants"
        v-bind="sizePickerPantsProps"
      />
      <SizePickerRegular
        v-if="props.sizePickerType === SizePickerTypes.SizePickerRegular"
        v-bind="sizePickerRegularProps"
      />
    </div>
    <!-- Display modelSizeInformation if it's not an empty string -->
    <div v-if="modelSizeInformation" class="product__model-information">
      {{ modelSizeInformation }}
    </div>

    <div v-if="isProductInStockBool" class="product__add">
      <input type="hidden" value="1" :name="skuParameterCode" />
      <button
        class="btn btn--profile btn--inset u-display-block btn--add-to-cart product__add-to-cart-btn"
        type="submit"
      >
        {{ addToBasket }}
      </button>
    </div>

    <!-- Disabled button for out-of-stock case -->
    <div v-else class="product__add">
      <button
        class="btn btn--add-to-cart u-display-block product__add-to-cart-btn"
        type="submit"
        disabled
      >
        {{ addToBasket }}
      </button>

      <!-- Sold out message only if product available in physical store -->
      <div v-if="isProductAvailableInStoreBool" class="product__soldout">
        <span class="product__soldout--text">{{ soldoutNews }}</span>
      </div>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { computed, onMounted, watch } from "vue";
import SizePickerSuit from "./sizePickerSuit.vue";
import SizePickerPants from "./sizePickerPants.vue";
import SizePickerRegular from "./sizePickerRegular.vue";
import { useAddToCartStore } from "./stores/addToCartStore";
import { LogErrorResponse } from "../../utils/API/Logging/LoggingAPI";
import { vueComponentToggler } from "../../../../global/modules/toggler_wp";
import { vueInventory } from "../../../../global/modules/inventory_wp";
import Icon from "../../components/icon/icon.vue";
import { IconSizes, IconTypes } from "../../components/icon/icon.model";

const addToCartStore = useAddToCartStore();
const CONTROLLER_URL = "/Cart/AddToCart";
const API_URL = "/publicapi/Inventory/FewItemsLeft";

const props = defineProps<{
  sizePickerType: string; // 'suit', 'pants', or 'regular'
  sizesMatrixJson: string;
  modelSizeInformation: string;
  chooseSize: string;
  chooseWidth: string;
  chooseLength: string;
  sizeGuide: string;
  isSizeGuide: string;
  selectSizeMessage: string;
  selectWidthMessage: string;
  selectLengthMessage: string;
  skuParameterCode: string;
  sizesListJson?: string; // Only needed for sizePickerRegular
  addToBasket: string;
  isProductInStock: string;
  soldoutNews: string;
  productAvailableInStore: string;
  inventoryNotificationTrigger: string;
}>();

const SizePickerTypes = {
  SizePickerSuit: "suit",
  SizePickerPants: "pants",
  SizePickerRegular: "regular",
} as const;

const isProductInStockBool = computed(() => {
  return props.isProductInStock === "True";
});

const isProductAvailableInStoreBool = computed(() => {
  return props.productAvailableInStore === "True";
});

const commonProps = {
  sizeGuide: props.sizeGuide,
  isSizeGuide: props.isSizeGuide === "True",
  skuParameterCode: props.skuParameterCode,
  modelSizeInformation: props.modelSizeInformation,
  inventoryNotificationTrigger: props.inventoryNotificationTrigger,
};

const sizePickerRegularProps = computed(() => {
  return {
    ...commonProps,
    sizesListJson: props.sizesListJson,
    chooseSize: props.chooseSize,
    selectSizeMessage: props.selectSizeMessage,
  };
});

const sizePickerPantsProps = computed(() => {
  return {
    ...commonProps,
    sizesMatrixJson: props.sizesMatrixJson,
    chooseWidth: props.chooseWidth,
    chooseLength: props.chooseLength,
    selectWidthMessage: props.selectWidthMessage,
    selectLengthMessage: props.selectLengthMessage,
  };
});

const sizePickerSuitProps = computed(() => {
  return {
    ...commonProps,
    sizesMatrixJson: props.sizesMatrixJson,
    chooseSize: props.chooseSize,
    chooseWidth: props.chooseWidth,
    chooseLength: props.chooseLength,
    selectSizeMessage: props.selectSizeMessage,
    selectWidthMessage: props.selectWidthMessage,
    selectLengthMessage: props.selectLengthMessage,
  };
});

const validateRegularSizeSelection = () => {
  if (!addToCartStore.sizeSelected) {
    addToCartStore.displaySizeNotSelectedError = true;
    return false;
  } else {
    addToCartStore.displaySizeNotSelectedError = false;
    return true;
  }
};

const validatePantsSizeSelection = () => {
  if (addToCartStore.widthSelected && addToCartStore.lengthSelected) {
    addToCartStore.displayWidthNotSelectedError = false;
    addToCartStore.displayLengthNotSelectedError = false;
    return true;
  } else {
    if (!addToCartStore.widthSelected) {
      addToCartStore.displayWidthNotSelectedError = true;
    } else if (!addToCartStore.lengthSelected) {
      addToCartStore.displayLengthNotSelectedError = true;
    }
    if (!addToCartStore.lengthSelected) {
      addToCartStore.displayLengthNotSelectedError = true;
    } else if (!addToCartStore.widthSelected) {
      addToCartStore.displayWidthNotSelectedError = true;
    }
    return false;
  }
};

const validateSuitSizeSelection = () => {
  if (
    addToCartStore.sizeSelected &&
    addToCartStore.widthSelected &&
    addToCartStore.lengthSelected
  ) {
    addToCartStore.displaySizeNotSelectedError = false;
    addToCartStore.displayWidthNotSelectedError = false;
    addToCartStore.displayLengthNotSelectedError = false;
    return true;
  } else {
    if (!addToCartStore.sizeSelected) {
      addToCartStore.displaySizeNotSelectedError = true;
    } else if (addToCartStore.sizeSelected) {
      addToCartStore.displaySizeNotSelectedError = false;
    }
    if (!addToCartStore.widthSelected) {
      addToCartStore.displayWidthNotSelectedError = true;
    } else if (addToCartStore.widthSelected) {
      addToCartStore.displayWidthNotSelectedError = false;
    }
    if (!addToCartStore.lengthSelected) {
      addToCartStore.displayLengthNotSelectedError = true;
    } else if (addToCartStore.lengthSelected) {
      addToCartStore.displayLengthNotSelectedError = false;
    }
    return false;
  }
};

// Validate size selection based on sizePickerType
const validateSizeSelection = () => {
  switch (props.sizePickerType) {
    case "suit":
      if (validateSuitSizeSelection()) return true;
      break;
    case "pants":
      if (validatePantsSizeSelection()) return true;
      break;
    case "regular":
      if (validateRegularSizeSelection()) return true;
    default:
      return false;
  }
};

const gtmPush = (gtmString: string) => {
  // eslint-disable-next-line
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push(gtmString);
};

// Failure toast animation logic
function failureToast() {
  const toastMessage = document.querySelector(".toast-message");
  const toast = document.querySelector(".toast");

  if (toastMessage && toast) {
    toastMessage.classList.remove("is-hidden");
    toast.classList.add("is-animating");

    setTimeout(() => {
      toast.classList.remove("is-animating");
      toastMessage.setAttribute("aria-hidden", "true");
    }, 3000);

    setTimeout(() => {
      toastMessage.classList.add("is-hidden");
    }, 4000);
  }
}

// Helper function to handle the failure toast logic
function showFailureToast(message: string) {
  const toastMessage = document.querySelector(".toast-message");
  const toast = document.querySelector(".toast");

  if (toastMessage && toast) {
    toastMessage.textContent = message;
    toastMessage.setAttribute("aria-hidden", "false");
    toast.classList.add("error");

    // Animate toast
    failureToast();

    // Reset the toast after 4 seconds
    setTimeout(() => {
      toastMessage.classList.remove("error");
    }, 4000);
  }
}

function displayCartStatus() {
  const cartStatusSizeElement = document.querySelector(
    "._js-cart-status-size",
  ) as HTMLElement;
  if (cartStatusSizeElement) {
    cartStatusSizeElement.innerText = "Størrelse: " + addToCartStore.sizeText;
  }

  const status = document.querySelector("._js-cart-status") as HTMLElement;
  status.classList.add("is-active");
  status.setAttribute("aria-hidden", "false");

  const cartStatusToCartElement = document.querySelector(
    "._js-cart-status-tocart",
  ) as HTMLElement;
  if (cartStatusToCartElement) {
    cartStatusToCartElement.focus();
  }
  Array.from(document.querySelectorAll("._js-cart-status-close")).forEach(
    (node) =>
      node.addEventListener("click", () => {
        status.classList.remove("is-active");
        status.setAttribute("aria-hidden", "true");
        const addToCartButton = document.querySelector(
          "._js-add-to-cart",
        ) as HTMLElement;
        if (addToCartButton) {
          addToCartButton.focus();
        }
      }),
  );
}

async function checkStockStatus(count: string): Promise<void> {
  const url = API_URL;
  const response = await fetch(`${url}?inventoryCount=${count}&`);

  if (!response.ok) {
    await LogErrorResponse(url, response);
    return Promise.reject("Det skjedde en feil under sjekk av få på lager.");
  }

  const inventoryIsLow = await response.json();
  if (inventoryIsLow) {
    addToCartStore.inventoryIsLow = true;
  } else {
    addToCartStore.inventoryIsLow = false;
  }

  addToCartStore.setCheckInventoryStatus(false);
}

async function addToCart(): Promise<void> {
  const url = `${CONTROLLER_URL}`;
  const formData = {
    c: addToCartStore.skuCode,
    q: 1,
  };
  const body = JSON.stringify(formData);

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: body,
  });

  if (!response.ok) {
    await LogErrorResponse(url, response, body);
    showFailureToast("Det skjedde en feil. Prøv igjen senere");
    return Promise.reject("Det skjedde en feil. Prøv igjen senere");
  }

  displayCartStatus();
  gtmPush(JSON.stringify(response));
  addToCartStore.incrementCartItemCount();
}

const submitForm = () => {
  if (!validateSizeSelection()) {
    return;
  }

  addToCart();
};

onMounted(() => {
  vueComponentToggler();
  vueInventory();
});

watch(
  () => addToCartStore.checkInventoryStatus,
  (newValue) => {
    if (newValue === true) {
      // Trigger the API call with the inventory count value
      checkStockStatus(addToCartStore.inventoryCount);
    }
  },
);
</script>
